/* Projects Section */
.projects-section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    font-family: 'Montserrat', sans-serif;
}

.projects-title {
    font-size: 2.4em;
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
}

/* Flexbox container for slider */
.projects-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure full-width */
    margin: 0 auto;
    padding: 0;
}

/* Slider Container */
.projects-slider {
    width: 105%;
}

/* Slick track adjustment */
.slick-track {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

/* Individual Project Card Styling */
.project-card {
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    padding: 30px; /* Added padding for more breathing room */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 600px; /* Set a consistent height */
    box-sizing: border-box;
    margin: 10px; /* Added margin to ensure there's space between cards */
}

.project-card:hover {
    transform: translateY(-10px);
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.2);
}

/* Project Image Styling */
.project-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Technologies used in each project */
.technologies {
    font-size: 0.9em;
    color: #666;
    margin-bottom: 20px;
}

/* Link Styling */
.project-link {
    text-decoration: none;
    color: #ffffff;
    background-color: #666;
    padding: 12px 18px;
    border-radius: 8px;
    font-weight: 600;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: inline-block;
    margin-top: auto;
}

.project-link:hover {
    background-color: #444;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Adding space between the slides */
.slick-slide {
    padding: 0 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect width calculation */
    width: 100%; /* Ensure full-width */
}

/* For smaller screens */
@media (max-width: 1024px) {
    .slick-slide {
        padding: 0 20px;
        box-sizing: border-box; /* Ensure padding doesn't affect width calculation */
        width: 100%; /* Ensure full-width */
    }
}

@media (max-width: 768px) {
    .projects-slider-container {
        flex-direction: column;
    }
}
