/* Import Montserrat Font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* Global Reset and Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333; /* Base text color */
  width: 100%; /* Ensures full width without borders */
  max-width: 100vw; /* Prevent horizontal scrolling */
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Main App Container */
#app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Header Styles */
header {
  text-align: center;
  padding: 30px;
  background-color: #282c34;
  color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

header h1 {
  margin: 0;
  font-size: 2.5em;
  font-weight: 700;
}

/* Navigation Links */
header nav a {
  margin: 0 15px;
  color: #666; /* Base color for links */
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

header nav a:hover {
  color: #333; /* Darker color on hover for emphasis */
}

/* Main Content Area */
.content {
  flex: 1;
  width: 100%;
  padding: 0; /* No padding */
  box-sizing: border-box;
}

/* Section Headers */
h2 {
  text-align: center;
  color: #282c34;
  margin-bottom: 30px;
}

/* General Link Styling */
a {
  color: #666;
  transition: color 0.3s ease;
}

a:hover {
  color: #333; /* Elegant darkening for links on hover */
}

/* Iframe Container Styles */
.malaria-inference-page,
.resume-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust height to account for header height */
  width: 100%;
  max-width: 100vw;
  overflow: hidden; /* Prevent extra scrolling */
}

iframe {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
  border-radius: 10px; /* Smooth corners for iframes */
}

/* Footer Styles */
footer {
  padding: 20px;
  text-align: center;
  color: #666;
  background-color: #f0f0f0;
  margin-top: auto;
}

/* Utility Classes */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Button Styles */
button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: #666;
  color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #333;
}

/* Resume and Malaria Inference Page Containers */
.resume-page-container,
.malaria-inference-page-container,
.openai-inference-page-container {
  display: flex;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 110px); /* Adjust height minus header */
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.resume-iframe-wrapper,
.malaria-iframe-wrapper,
.openai-iframe-wrapper {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.resume-iframe,
.malaria-iframe
.openai-iframe {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px; /* Optional for smoother corners */
}
