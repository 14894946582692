/* Updated Header Style to Match Submenu */
.glassy {
  background: rgba(20, 20, 30, 0.9); /* Darker background to match submenu */
  border: none; /* Removing border for a consistent look with submenu */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7); /* Similar shadow as submenu for depth */
  padding: 10px; /* Adjust padding to match submenu */
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
}

/* Header content - aligns logo and navbar */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* Logo container */
.logo img {
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease;
  border-radius: 50%; /* Optional, gives a circular logo effect */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.2); /* Glowing effect */
}

.logo img:hover {
  transform: scale(1.1);
}

/* Navigation Bar Styling */
.navbar {
  display: flex;
  align-items: center;
}

/* Navigation Bar Links - Sleeker Styling */
.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 25px;
  position: relative;
}

.navbar-links li {
  position: relative;
}

.navbar-links a {
  text-decoration: none;
  color: #ffffff;
  font-size: 1em;
  font-family: 'Montserrat', sans-serif; /* Modern and stylish */
  padding: 8px 0;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
}

/* Hover Effect for Sleek Interaction */
.navbar-links a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -4px; /* Space below the link */
  width: 0;
  height: 2px;
  background: #ffffff; /* White underline effect */
  transition: width 0.3s ease;
}

.navbar-links a:hover::after {
  width: 100%; /* Expand underline on hover */
}

.navbar-links a:hover {
  color: #00bcd4; /* Slightly different color to highlight */
  transform: translateY(-2px); /* Lift the link on hover for added effect */
}

/* Active Link Styling */
.active a {
  color: #00bcd4; /* A different color to indicate the active page */
  font-weight: bold;
}

/* Submenu with Indicator */
.submenu {
  display: none;
  position: absolute;
  top: calc(100% + 10px); /* Add spacing between main item and submenu */
  left: 0;
  background-color: rgba(20, 20, 30, 0.9); /* Darker submenu background */
  padding: 15px 10px; /* Added top padding for spacing and overall padding for content */
  border-radius: 8px;
  list-style: none;
  min-width: 200px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Submenu list items */
.submenu li {
  padding: 5px 0;
}

/* Submenu links - smaller and consistent styling */
.submenu li a {
  color: #ffffff;
  font-size: 0.9em; /* Reduced font size for a smaller appearance */
  padding: 8px 10px;
  border-radius: 4px;
  display: block;
  transition: background-color 0.3s ease;
}

.submenu li a:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

/* Ensure submenu stays open when hovering over either the parent or the submenu */
.navbar-links li:hover .submenu,
.submenu:hover {
  display: block;
  opacity: 1;
  visibility: visible;
}

/* Show submenu on hover */
.navbar-links li:hover .submenu {
  display: block;
  opacity: 1;
  visibility: visible;
}

/* Padding added to last link ("Malaria Inference") */
.navbar-links li:last-child a {
  padding-right: 10px;
}

/* Mobile Menu Toggle Button */
.mobile-menu-toggle {
  display: none;
  font-size: 2rem;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1100; /* Should be higher than header */
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block;
  }

  .navbar {
    display: none;
    gap: 10px;
    background: rgba(25, 25, 35, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
  }

  .navbar.active {
    display: flex;
  }

  .header-content {
    flex-direction: horizontal;
    align-items: center;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
  }

  .submenu {
    display: none;
  }
  
  .navbar-links li:hover .submenu {
    display: none; /* Disable submenu on hover for mobile */
  }

  .navbar-links {
    gap: 15px; /* More space between items for easier tapping */
  }
}
