/* Default (Larger UI for Bigger Screens) */
.about-section {
    padding: 60px 0;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.about-container {
    display: flex;
    flex-direction: row;
    width: 92%;
    max-width: 1800px;
    align-items: center;
    padding: 40px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    gap: 50px;
}

.image-container {
    flex-shrink: 0;
    margin-right: 30px;
}

.profile-image {
    width: 260px;
    height: 260px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border: 4px solid rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-image:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
}

.name {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 15px;
    line-height: 1.2;
    text-align: center;
}

.about-container .title {
    font-size: 2em;
    color: #444;
    text-align: center;
}

/* Contact Information Styling */
.about-container .contact-info {
    font-size: 1em;
    color: #444;
}

.about-container .contact-item {
    display: flex;
    align-items: center;
    font-weight: 400;
}

.about-container .contact-icon {
    margin-right: 10px;
    font-size: 1.5em;
}

.about-container .contact-item a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
}

.about-container .contact-item a:hover {
    color: #333;
}

/* About Section Link Styling */
.about-container .links {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.about-container .links a {
    color: #666;
    text-decoration: none;
    font-weight: 600;
    padding: 8px 12px;
    border-radius: 8px;
    transition: all 0.3s ease;
    background-color: rgba(72, 88, 109, 0.1);
}

.about-container .links a:hover {
    color: #ffffff;
    background-color: #666;
    border: 2px solid #666;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-container .about-description {
    line-height: 1.8;
    font-size: 1.2em;
    color: #444;
}

.about-container .about-description p {
    margin-bottom: 20px;
}

.about-container .about-description strong {
    color: #444;
    font-weight: 600;
}

/* Responsive Design */
@media (max-width: 900px) {
    .about-section {
        padding: 40px 0; /* Reduced padding for smaller screens */
    }

    .about-container {
        flex-direction: column;
        text-align: center;
        padding: 30px;
        gap: 30px; /* Smaller gap between elements */
    }

    .image-container {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .profile-image {
        width: 200px; /* Smaller image for mobile */
        height: 200px;
    }

    .name {
        font-size: 3em; /* Smaller font size for mobile */
    }

    .about-container .title {
        font-size: 2em;
    }

    .links {
        justify-content: center;
        gap: 15px; /* Smaller gap for mobile links */
    }

    .about-container .about-description {
        font-size: 1.1em; /* Slightly smaller font size */
    }
}

/* Smaller view for very small screens (max-width: 480px) */
@media (max-width: 480px) {
    .about-section {
        padding: 30px 0; /* Even smaller padding for very small screens */
    }

    .about-container {
        padding: 20px;
        gap: 20px;
    }

    .profile-image {
        width: 150px; /* Even smaller image */
        height: 150px;
    }

    .name {
        font-size: 3em; /* Further reduced font size */
    }

    .about-container .title {
        font-size: 1.5em;
    }

    .links {
        flex-direction: column; /* Stack links vertically for very small screens */
        gap: 10px;
    }

    .about-container .about-description {
        font-size: 1em; /* Reduced font size */
    }
}
