.contact-section {
    padding: 60px 20px;
    background-color: #f4f4f4; /* Light grey background for contrast */
    font-family: 'Montserrat', sans-serif; /* Modern and stylish font */
}

.contact-title {
    font-size: 2.4em;
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
    letter-spacing: 1px;
}

/* Flex container for contact information and map */
.contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Contact Information Styling */
.contact-info {
    flex: 1;
    min-width: 280px;
    max-width: 500px;
}

.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.contact-icon {
    font-size: 1.6em;
    color: #666; /* Feature color for contact icons */
    margin-right: 15px;
}

.contact-item p, .contact-item a {
    font-size: 1.1em;
    color: #444;
    text-decoration: none;
}

.contact-item a:hover {
    color: #1a73e8; /* Blue color for hover effect on links */
    text-decoration: underline;
}

/* Google Maps Embed Styling */
.map-container {
    flex: 1;
    min-width: 280px;
    max-width: 500px;
    height: 450px; /* Increase the height to match the iframe */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center; /* Center contact information and map on smaller screens */
    }

    .contact-info, .map-container {
        width: 90%; /* Full width for smaller screens */
        margin-bottom: 20px;
    }
}
