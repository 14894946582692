@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

#education {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: 'Montserrat', sans-serif;
}

.education-title {
    font-size: 2.4em;
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
    letter-spacing: 1px;
}

/* Grid container for wrapping the cards */
.education-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default to 4 cards side by side */
    gap: 20px; /* Space between cards */
    max-width: 1280px; /* Limit maximum width for better scaling */
    margin: 0 auto; /* Center the container */
    padding: 20px;
}

/* Cards grow/shrink based on available space */
.education-card {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    min-width: 250px; /* Ensure a minimum width */
    height: 400px; /* Fixed height */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure even spacing inside the card */
}

.education-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Ensures logos scale well within the cards */
.education-logo-container {
    height: 200px; /* Fixed height to allow for logo scaling */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.education-logo {
    max-height: 100%; /* Allow logo to take up full height of the container */
    max-width: 100%; /* Ensure logos don't overflow horizontally */
    object-fit: contain; /* Maintain aspect ratio */
    padding: 10px; /* Add padding to ensure breathing space around the logo */
}

.education-content {
    padding: 15px;
    text-align: center;
    font-family: 'Montserrat', sans-serif; /* Apply the font to content */
    flex-grow: 1; /* Allow content to fill available space */
}

.education-content h3 {
    margin: 10px 0 5px;
    font-size: 1.2em;
    font-weight: 700; /* Use a bold weight for institution name */
}

.education-content p {
    margin: 0;
    color: #666;
    font-weight: 500; /* Medium weight for text clarity */
}

/* Preview section for hover */
.education-preview {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    opacity: 0;
    visibility: hidden;
    font-family: 'Montserrat', sans-serif; /* Consistent font */
}

.education-card:hover .education-preview {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Keep it above all other elements */
    font-family: 'Montserrat', sans-serif; /* Apply font globally in modal */
}

.modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 15px;
    width: 90%;
    max-width: 800px; /* Ensure the modal is not too wide */
    max-height: 90vh; /* Make sure modal is not taller than the viewport */
    overflow-y: auto; /* Add scroll for the content if it exceeds the height */
    position: relative;
    z-index: 10000;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5em;
    cursor: pointer;
    z-index: 10001;
    background: transparent;
    border: none;
    color: #666;
    transition: color 0.3s ease;
}

.close-button:hover {
    color: #333;
}

/* New Close Button at Bottom */
.close-modal-bottom {
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 30px auto 0;
    padding: 10px 20px;
    font-size: 1.2em;
    color: #fff;
    background-color: #666; /* Use grey instead of blue */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.close-modal-bottom:hover {
    background-color: #444; /* Darker grey on hover */
    transform: scale(1.05);
}

/* Modal Content Styling */
.modal-content h3 {
    font-size: 2em;
    color: #444; /* Darker grey for header consistency */
    margin-bottom: 15px;
    font-weight: 700;
}

.modal-content h4 {
    font-size: 1.4em;
    color: #333;
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 600;
    border-bottom: 2px solid #666;
    padding-bottom: 5px;
}

.modal-content ul {
    list-style-type: disc;
    margin: 15px 0 15px 20px;
}

.modal-content li {
    font-size: 1.1em;
    line-height: 1.8;
    color: #555;
    margin-bottom: 10px;
}

/* Styling for the Visit School Website Link */
.modal-content a {
    color: #666;
    font-weight: 600;
    text-decoration: none;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    border-bottom: 2px solid transparent;
}

.modal-content a:hover {
    color: #333;
    border-bottom: 2px solid #333; /* Adds a subtle underline effect */
}

/* Responsive Design */

/* 2 cards side by side on smaller screens */
@media (max-width: 1024px) {
    .education-container {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
}

/* 1 card on very small screens */
@media (max-width: 768px) {
    .education-container {
        grid-template-columns: 1fr; /* 1 column */
    }

    .education-logo-container {
        height: 150px; /* Adjust logo container height for smaller screens */
    }
}

@media (max-width: 480px) {
    .modal-content {
        padding: 20px; /* Reduce modal padding on very small screens */
        max-width: 95%; /* Adjust modal width for very small screens */
    }

    .modal-content h3 {
        font-size: 1.6em; /* Adjust modal heading font size for small screens */
    }
}
