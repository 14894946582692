/* Default (Larger UI for Bigger Screens) */
/* Skills Section */
.skills-section {
    padding: 60px 20px;
    background: linear-gradient(to right, #f3f4f7, #f9f9f9);
    font-family: 'Montserrat', sans-serif;
}

.skills-title {
    font-size: 2.4em;
    text-align: center;
    color: #333;
    margin-bottom: 40px;
    font-weight: 700;
    letter-spacing: 1px;
}

/* Grid-based layout for skill cards */
.skills-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Ensures no more than 2 side-by-side */
    gap: 30px; /* Space between cards */
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Card styling for each skill category */
.skill-category {
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border-radius: 15px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 280px;
    max-width: 100%; /* Ensures cards fit within grid */
}

.skill-category:hover {
    transform: translateY(-10px);
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.15);
}

.skill-category h3 {
    font-size: 1.6em;
    color: #666;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

/* Icon styling */
.skill-icon {
    font-size: 2em;
    color: #666;
    margin-right: 10px;
}

/* Styling for skill list items */
.skill-category ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
}

.skill-category li {
    font-size: 1.1em;
    line-height: 1.8;
    color: #555;
    padding-left: 20px;
    position: relative;
}

.skill-category li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #444;
    font-size: 1.2em;
}

/* Responsive styling for medium screens */
@media (max-width: 1200px) {
    .skills-container {
        grid-template-columns: repeat(2, 1fr); /* Keep 2 cards side by side */
        gap: 25px;
    }
}

/* Grid-based layout for smaller screens (max-width: 768px) */
@media (max-width: 768px) {
    .skills-section {
        padding: 40px 15px;
    }

    .skills-title {
        font-size: 2em;
        margin-bottom: 30px;
    }

    .skills-container {
        grid-template-columns: repeat(2, 1fr); /* Keep 2 cards side by side */
        gap: 20px;
    }

    .skill-category {
        padding: 15px;
        min-height: 180px; /* Adjusted height for smaller screens */
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    }

    .skill-category h3 {
        font-size: 1.3em; /* Slightly smaller headings */
        margin-bottom: 10px;
    }

    .skill-icon {
        font-size: 1.6em; /* Slightly smaller icons for compact cards */
    }

    .skill-category li {
        font-size: 0.9em; /* Slightly smaller text */
        line-height: 1.6;
        padding-left: 15px;
    }

    .skill-category li::before {
        font-size: 1.1em; /* Adjust bullet size */
    }
}

/* Grid-based layout for very small screens (max-width: 480px) */
@media (max-width: 480px) {
    .skills-container {
        grid-template-columns: repeat(2, 1fr); /* Always keep 2 cards side by side */
        gap: 15px;
    }

    .skill-category {
        padding: 12px;
        min-height: 160px;
    }

    .skill-category h3 {
        font-size: 1.1em;
        margin-bottom: 8px;
    }

    .skill-icon {
        font-size: 1.4em; /* Smaller icons */
    }

    .skill-category li {
        font-size: 0.85em;
        line-height: 1.5;
        padding-left: 10px;
    }

    .skill-category li::before {
        font-size: 1em;
    }
}
